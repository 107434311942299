<template>
  <div class="desktop-keyboard-button" :draggable="isDraggable" @dragstart="handleDragstart">
    <div
      @mousedown="isDraggable = true"
      class="desktop-keyboard-button__drag-zone">
      <div class="desktop-keyboard-button__drag-zone-content"/>
    </div>
    <div class="desktop-keyboard-button__controls-row">
      <el-dropdown
        trigger="click"
        @command="handleTypeSelect">
        <button class="button button_size_xs">
          {{buttonTypeLabel}}
          <el-icon name="arrow-down"/>
        </button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="type in buttonTypes"
            :key="type"
            :command="type">
            {{getButtonTypeLabel(type)}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div class="spacer"/>

      <transition name="fade-200">
        <keyboard-button-sign
          :is-error="isError"
          class="desktop-keyboard-button__sign"/>
      </transition>

      <button
        class="button button_theme_danger button_size_xs"
        :title="$t('keyboard.deleteButton')"
        @click="deleteButton">
        <el-icon name="delete"/>
      </button>
    </div>

    <el-autocomplete
      :fetch-suggestions="searchTextSuggestions"
      :placeholder="$t('keyboard.placeholders.text')"
      :value="text"
      @input="updateText($event)"/>

    <div
      class="keyboard-button__article-row"
      v-if="type === 'article'">
      <button
        class="button keyboard-button__article-button"
        @click.stop="toggleEditorJsPopover">
        {{$t('keyboard.openEditor')}}
      </button>
      <label class="switch ml-1 keyboard-button__article-button">
        <input
          type="checkbox"
          class="switch__input"
          :checked="isIndexed"
          @input="$emit('update:is-indexed', $event.target.checked)"/>
        <span class="switch__core">
          <div class="switch__icon"/>
          {{$t('keyboard.addToStats')}}
        </span>
      </label>
    </div>

    <transition name="fade">
      <editor-js-popup
        v-if="editorJsPopupActive"
        :title="text"
        @update:title="$emit('update:text', $event)"
        :content="content"
        @update:content="$emit('update:content', $event)"
        @close="handleDone"
        @delete="deleteButton"/>
    </transition>

    <div class="answer-input" v-if="type === 'answer'">
      <el-input
        type="textarea"
        autosize
        :placeholder="$t('keyboard.placeholders.answer')"
        :value="answer"
        @input="$emit('update:answer', $event)"/>

      <el-tooltip
        effect="dark"
        :content="charCounterTooltip"
        placement="top"
        v-if="charLimitExceeded">
        <span class="answer-input__char-counter" :class="{'answer-input__char-counter_danger': charLimitExceeded}">
          {{answer.length}}/{{answerCharLimit}}
        </span>
      </el-tooltip>
      <span
        class="answer-input__char-counter"
        :class="{'answer-input__char-counter_danger': charLimitExceeded}"
        v-else>
        {{answer.length}}/{{answerCharLimit}}
      </span>
    </div>

    <el-input
      v-if="type === 'link'"
      :placeholder="$t('keyboard.placeholders.link')"
      :value="link"
      @input="$emit('update:link', $event)"/>
  </div>
</template>

<script>
  import KeyboardButtonSign from '@/components/Keyboard/KeyboardButtonSign.vue'

  const EditorJsPopup = () => import('@/components/Keyboard/EditorJsPopup.vue')

  import KeyboardButtonMixin from '@/components/Keyboard/KeyboardButton/KeyboardButtonMixin'

  export default {
    name: 'KeyboardButton',
    components: {
      KeyboardButtonSign,

      EditorJsPopup,
    },
    mixins: [
      KeyboardButtonMixin,
    ],

    data: () => ({
      isDraggable: false,
    }),

    methods: {
      handleDragstart(event) {
        if (!this.isDraggable) {
          return false
        }
        event.dataTransfer.setData('application/json', JSON.stringify([this.rowIndex, this.columnIndex]))
      },

      handleDocumentDrop() {
        this.isDraggable = false
      }
    },

    mounted() {
      document.addEventListener('drop', this.handleDocumentDrop)
    },
    beforeDestroy() {
      document.removeEventListener('drop', this.handleDocumentDrop)
    }
  }
</script>

<style scoped lang="stylus">
  @import '~@/components/Keyboard/KeyboardButton/KeyboardButton.styl'

  .desktop-keyboard-button
    max-width 400px

    @apply flex flex-col flex-grow p-1 relative overflow-hidden
    @apply bg-white border border-gray-300 rounded

    > :not(:first-child)
      @apply mt-1

  .desktop-keyboard-button__drag-zone-wrapper
    @apply flex flex-row justify-center
    top 2px

  .desktop-keyboard-button__drag-zone
    @apply absolute inset-x-0 z-10
    width 46px
    height 11px
    margin auto
    padding 3px
    @apply cursor-move

  .desktop-keyboard-button__drag-zone-content
    @apply w-full h-full
    @apply bg-gray-200 rounded-full
    transition .1s

    .desktop-keyboard-button:hover &
      @apply bg-gray-400

  .desktop-keyboard-button__controls-row
    @apply flex flex-row items-center

  .desktop-keyboard-button__sign
    @apply mr-1
</style>

<style lang="stylus">
  .answer-input
    .el-textarea__inner
      padding 8.5px 15px
</style>
