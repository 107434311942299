import { render, staticRenderFns } from "./DesktopKeyboardButton.vue?vue&type=template&id=e7740496&scoped=true&"
import script from "./DesktopKeyboardButton.vue?vue&type=script&lang=js&"
export * from "./DesktopKeyboardButton.vue?vue&type=script&lang=js&"
import style0 from "./DesktopKeyboardButton.vue?vue&type=style&index=0&id=e7740496&scoped=true&lang=stylus&"
import style1 from "./DesktopKeyboardButton.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7740496",
  null
  
)

export default component.exports