<template>
  <i
    v-if="isError || isWarn"
    class="material-icons keyboard-button__sign"
    :class="{'keyboard-button__sign_error': isError, 'keyboard-button__sign_warning': isWarn}">error_outline</i>
</template>

<script>
  export default {
    name: 'KeyboardButtonSign',
    props: {
      isError: Boolean,
      isWarn: Boolean,
    }
  }
</script>

<style scoped lang="stylus">
  .keyboard-button__sign
    transition 200ms

  .keyboard-button__sign_warning
    @apply text-orange-500

  .keyboard-button__sign_error
    @apply text-red-500
</style>