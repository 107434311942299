export default {
  props: {
    type: String,
    text: String,
    answer: {
      type: String,
      default: '',
    },
    link: String,
    isIndexed: Boolean,
    content: {
      type: Object,
      default: () => ({}),
    },

    answerCharLimit: {
      type: Number,
      default: 200,
    },
    channelLanguage: {
      type: String,
      default: 'ru',
    },
    answerStatsEnabled: Boolean,

    rowIndex: Number,
    columnIndex: Number,
  },
  data() {
    return {
      initialData: this.content || {},

      editorJsPopupActive: false,

      buttonTypes: [
        'answer',
        'article',
        'link',
        'reaction',
        'stats',
        'comments',
      ],
    }
  },
  methods: {
    deleteButton() {
      this.$emit('delete')
    },

    toggleEditorJsPopover() {
      if (this.editorJsPopupActive) {
        return this.handleDone()
      }
      this.editorJsPopupActive = true
    },

    handleDone() {
      if (!this.editorJsPopupActive) {
        return
      }
      if (this.type === 'article') {
        this.updateInitialData()
      }
      this.editorJsPopupActive = false
      this.$emit('blur')
    },

    handleTypeSelect(type) {
      this.$emit('update:type', type)
    },

    updateInitialData() {
      this.initialData = this.content
    },

    updateText(text) {
      let newText = text
        .replace(/&nbsp;/g, ' ')
      if (newText.length > 40) {
        newText = newText.slice(0, text.charCodeAt(39) === text.codePointAt(39) ? 40 : 39)
        // проверяет, есть ли эмодзи/другой 16-битный символ на конце
        // если есть, то удаляет и первую половину
      }
      this.$emit('update:text', newText)
    },

    searchTextSuggestions(queryString, cb) {
      const allResults = (() => {

        if (this.type === 'stats') {
          return [
            '📊',
            ...{
              ru: [
                'Статистика',
                '📊 Статистика',
                'Статистика ответов',
                '📊 Статистика ответов',
              ],
              en: [
                'Stats',
                '📊 Stats',
                'Answers stats',
                '📊 Answers stats',
              ],
              ua: [
                'Статистика',
                '📊 Статистика',
                'Статистика відповідей',
                '📊 Статистика відповідей',
              ],
              uz: [
                'Javoblar statistikasi',
                '📊 Javoblar statistikasi',
              ],
            }[this.channelLanguage],
          ]
        }
        if (this.type === 'comments') {
          return [
            '💬',
            ...{
              ru: [
                'Открыть комментарии',
                '💬 Открыть комментарии',
                'Комментарии',
                '💬 Комментарии',
              ],
              en: [
                'Open comments',
                '💬 Open comments',
                'Comments',
                '💬 Comments',
              ],
              ua: [
                'Відкрити коментарі',
                '💬 Відкрити коментарі',
              ],
              uz: [
                'Open comments',
                '💬 Open comments',
                'Comments',
                '💬 Comments',
              ],
              uz_cyrillic: [
                'Открыть комментарии',
                '💬 Открыть комментарии',
                'Комментарии',
                '💬 Комментарии',
              ],
            }[this.channelLanguage]
          ]
        }

        return []
      })().map(value => ({value}))

      const results = queryString
        ? allResults.filter((link) => (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0))
        : allResults
      return cb(results)
    },

    getButtonTypeLabel(type) {
      return this.$t(`keyboard.buttonTypeLabel.${type}`)
    }
  },
  computed: {
    buttonEmpty() {
      return !this.text || this.text.trim().length === 0
    },
    contentEmpty() {
      if (this.type === 'answer') {
        return !(this.answer && this.answer.trim().length > 0)
      }
      if (this.type === 'article') {
        return false
        // return !this.content || !this.content.blocks || (this.content.blocks.length === 0)
      }
      if (this.type === 'link') {
        return !this.link || this.link.length === 0
      }
      return false
    },

    buttonTypeLabel() {
      return this.getButtonTypeLabel(this.type)
    },

    isError() {
      return this.contentEmpty || this.buttonEmpty
    },

    charLimitExceeded() {
      return this.answer.length > this.answerCharLimit
    },
    charCounterTooltip() {
      if (this.answerStatsEnabled) {
        return 'This text won\'t fit in the button. You can disable answer stats to increase the limit'
      }
      return 'This text won\'t fit in the button.'
    },
  },
  watch: {
    type(type) {
      if (type !== 'article') {
        this.updateInitialData()
      }
    },
  },
}